import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Divider, Stack } from "@mui/material";
import { getCurrencySymbol, getPlanPrice } from "@/Pages/Config/Account/billingHelper";
import { useGetPaymentLink } from "@/Api/genApi";
import { BillingPlan, BillingPlanCurrency, BillingPlanPaymentTerm } from "@/Api/genApi.schemas";
import { Button } from "@/Components/Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { W_NumberField } from "@shared/Components/FormComponents/NumberField/W_NumberField";
import { W_NewDropdown } from "@shared/Components/FormComponents/NewDropdown/W_NewDropdown";

interface ChangePlanModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export function BuyGodeskModal(props: ChangePlanModalProps) {
	const paymentLinkReq = useGetPaymentLink();

	const { handleSubmit, reset, control, watch } = useForm<BillingPlan>({
		defaultValues: {
			agentCount: 3,
			paymentTerm: BillingPlanPaymentTerm.MONTHLY,
			currency: BillingPlanCurrency.GBP
		}
	});

	function close() {
		reset();
		props.closeModal();
	}

	async function onSubmit(data: BillingPlan) {
		paymentLinkReq.mutate({ data: data }, {
			onSuccess: response => window.location.assign(response),
			onError: error => {console.log(error); toast.error("Could not activate: " + error.message);}
		});
	}

	const currentAgentCount = watch("agentCount");
	const currentCurrency = watch("currency");
	const currentPaymentTerm = watch("paymentTerm");

	const currentCurrencySymbol = getCurrencySymbol(currentCurrency);
	const currentSeatPrice = getPlanPrice(currentCurrency, currentPaymentTerm);

	const totalPrice = currentSeatPrice * currentAgentCount;
	const agentCountIsValid = currentAgentCount != null;

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			modalStyle={{
				maxWidth: "350px",
				minWidth: "450px",
			}}
			title="Activate GoDesk"
		>
			<ModalContent>
				<form onSubmit={handleSubmit(onSubmit)} className="pb-2">
					<Stack spacing={3}>
						<div>Choose the plan that's right for you.</div>

						<Stack className="w-[300px]" spacing={3}>
							<W_NumberField
								control={control}
								name="agentCount"
								label="Agent seats"
								required
								endAdornment="Seats"
								min={1}
								max={999}
								fullWidth
							/>

							<W_NewDropdown
								control={control}
								name="currency"
								label="Currency"
								required
								options={[
									{ label: "£ GBP - Pound sterling", value: BillingPlanCurrency.GBP },
									{ label: "$ USD - United States dollar", value: BillingPlanCurrency.USD },
									{ label: "€ EUR - Euro", value: BillingPlanCurrency.EUR }
								]}
							/>

							<W_NewDropdown
								control={control}
								name="paymentTerm"
								label="Billing cycle"
								required
								options={[
									{ label: "Monthly", value: BillingPlanPaymentTerm.MONTHLY },
									{ label: "Yearly (10% discount)", value: BillingPlanPaymentTerm.YEARLY },
								]}
								helperText="10% Discount on yearly subscriptions."
							/>
						</Stack>

						<Divider />

						<div className="grid grid-cols-[1fr_1fr_1fr_1fr_150px] grid-rows-2 gap-3 font-semibold text-base">
							<div className="font-medium">Plan</div>
							<div className=""></div>
							<div className="font-medium">Agents</div>
							<div className=""></div>
							<div className="font-medium">{currentPaymentTerm == BillingPlanPaymentTerm.YEARLY ? "Subtotal" : "Total"}</div>
							<div className="">{currentCurrencySymbol + currentSeatPrice}</div>
							<div className="">×</div>
							<div className="">{currentAgentCount}</div>
							<div className="self-center">=</div>
							<div className="">{currentCurrencySymbol + totalPrice} / month</div>
						</div>

						{currentPaymentTerm == BillingPlanPaymentTerm.YEARLY && <>
							<Divider />

							<div className="text-base w-full flex justify-between">
								<div>Billed annually</div>
								<div className="font-semibold w-[150px]">{currentCurrencySymbol + (totalPrice * 12)} / year</div>
							</div>
						</>}
					</Stack>
				</form>
			</ModalContent>

			<ModalButtons>
				<Button btnClass="btn-grey"label="Cancel" onClick={close} />

				<Button
					btnClass="btn-blue"
					className="px-4"
					onClick={() => handleSubmit(onSubmit)()}
					label="Confirm & purchase"
					disabled={paymentLinkReq.isPending || !agentCountIsValid}
					loading={paymentLinkReq.isPending}
				/>
			</ModalButtons>
		</Modal>
	);
}
