import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { ApiCreateEntity, ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import W_TextArea from "@shared/Components/FormComponents/TextArea/W_TextArea";
import { Entities } from "@shared/Entities/Entities";
import { KbFolder } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { getBase64 } from "@shared/Helpers/OtherFunctions";

interface kbFolderModalProps {
	data?: KbFolder;
	kbDropDownOptions?: string[];
	refreshData(): void;
	closeModal(): void;
	isOpen: boolean;
}

export default function KbFolderModal(props: kbFolderModalProps) {
	const { cache, refreshCache } = useCache();

	const { control, handleSubmit, reset, watch, setValue } = useForm<KbFolder>({ defaultValues: { ...props.data, kbId: 1 } });

	const editMode = props.data != null && props.data.id != 0;

	useEffect(() => {
		if (props.data != null) {
			reset(props.data);
		}
	}, [props.data]);

	async function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.files != null && event.target.files[0]) {
			const file: Blob = event.target.files[0];

			if (file.size > 16000000) {
				toast.error("Image is too big. Please choose a smaller file (< 16MB).");
			} else {
				const fileDataBase64 = String(await getBase64(file));
				const fileMimeType = file.type;

				if (fileDataBase64 != null && fileMimeType != null) {
					setValue("pictureData", fileDataBase64);
					setValue("pictureMimetype", fileMimeType);
				}
			}
		}
	}

	async function deleteFile() {
		setValue("pictureData", undefined);
		setValue("pictureMimetype", undefined);
		setValue("pictureFileName", "");

		if (props.data?.pictureFileName != null) {
			const sectionToPut = { ...props.data, pictureData: "-1" };

			const res = await ApiUpdateEntity(Entities.KBFOLDER, props.data?.id, sectionToPut);

			if (res.successful) {
				toast.success("Cleared.");
				props.refreshData();
			} else {
				toast.error("Could not clear folder image.");
			}
		}
	}

	async function submitForm(data: KbFolder) {
		if (data.id > 0) {
			const res = await ApiUpdateEntity(Entities.KBFOLDER, data.id, data);

			if (res.successful) {
				toast.success("Folder updated.");
				reset();
				refreshCache();
				props.refreshData();
				props.closeModal();
			} else {
				toast.error("Could not update folder.");
			}
		} else {
			const res = await ApiCreateEntity(Entities.KBFOLDER, data);

			if (res.successful) {
				toast.success("Folder created.");
				reset();
				refreshCache();
				props.refreshData();
				props.closeModal();
			} else {
				toast.error("Could not create a new folder.");
			}
		}
	}

	function cancelForm() {
		props.closeModal();
		reset();
	}

	const pictureUrl = props.data?.pictureFileName != null ? cache.getPicturesDir() + props.data.pictureFileName : undefined;

	const pictureData = watch("pictureData");
	const pictureMimeType = watch("pictureMimetype");

	const bannerImageName = pictureData == null && pictureUrl == null ? "Upload banner image" : "Change banner image";
	const displayImgPreview = (pictureData != null && pictureMimeType != null) || pictureUrl != null;

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title={editMode ? "Edit folder" : "Create folder"}
		>

			<ModalContent>
				<div className="px-4 min-w-[300px]">
					<W_TextInput
						control={control}
						dataname="name"
						label="Name"
						mandatory
						autoFocus
					/>

					<W_TextArea
						control={control}
						dataname="description"
						label="Description"
						rows={3}
					/>

					<W_CacheDropdown<KbFolder>
						control={control}
						cacheSlice={CacheSlices.KbFolders}
						filter={kbFolder => kbFolder.id != props.data?.id && props.data?.kbId == kbFolder.kbId}
						dataname="parentId"
						label="Parent folder"
					/>

					<div>Banner image</div>
					<label className="btn-grey">
						{bannerImageName}
						<input type="file" accept="image/*" onChange={onChangeHandler} style={{ display: "none" }} />
					</label>

					<div className="font-medium text-sm">
						For best results, use a 400x200 px image.
					</div>

					{ displayImgPreview &&
						<div className="flex flex-col">
							<img src={pictureData ?? pictureUrl} className="w-[400px] h-[200px] border" />
							<Button
								className="pt-1 px-2 space-x-2 rounded-bl-md rounded-tr-md"
								onClick={() => deleteFile()}
								label="Remove image"
							/>
						</div>
					}
				</div>
			</ModalContent>

			<ModalButtons>
				<Button
					onClick={cancelForm}
					label="Cancel"
				/>

				<ButtonBlue
					onClick={() => handleSubmit(submitForm)()}
					label={editMode ? "Update" : "Create"}
				/>
			</ModalButtons>
		</Modal>
	);
}
