import { useNavigate } from "react-router-dom";
import { Control, useFieldArray, useForm } from "react-hook-form";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import W_MultiEntitySelect from "@/Components/FormComponents/MultiEntitySelect/W_MultiEntitySelect";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { Button } from "@/Components/Button/Button";
import { useInviteAgents } from "@/Api/genApi";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { AgentInviteReq } from "@/Api/genApi.schemas";
import { W_Tickbox } from "@shared/Components/FormComponents/Tickbox/W_Tickbox";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { SettingInt } from "@shared/Enums/SettingEnums";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";

interface InviteAgentsModalProps {
	isOpen: boolean;
	closeModal(): void;
}

interface AgentInviteForm {
	invites: AgentInviteReq[];
}

const modalStyle = {
	maxWidth: "1000px",
	minWidth: "700px",
	maxHeight: "700px"
};

export function InviteAgentsModal(props: InviteAgentsModalProps) {
	const { cache } = useCache();
	const navigate = useNavigate();

	const inviteAgentsReq = useInviteAgents();

	const agentLimit = cache.getSettingInt(SettingInt.LIMITS_AGENTS) ?? 3;

	const activeAgentCount = cache.Agents.filter(agent => !agent.deleted && !agent.disabled && agent.id != GD_SUPPORT_AGENT_ID).length;

	const { handleSubmit, reset, control, getValues, watch } = useForm<AgentInviteForm>({
		defaultValues: {
			invites: [
				{ email: "", isAdmin: false, teamIds: [] }
			]
		}
	});

	async function onSubmit(data: AgentInviteForm) {
		if (data.invites.length < 1) {
			toast.error("Invite failed. No agents to invite.");
			return;
		}

		inviteAgentsReq.mutate({ data: data.invites }, {
			onSuccess: () => {
				toast.success("Invite successful.");
				reset();
				props.closeModal();
			},
			onError: error => {
				console.log(error);

				if (error.formFieldErrorCode != undefined && error.dataNumber1 != null) {
					OtherFunctions.displayEmailTakenErrorNormal(cache, navigate, error.formFieldErrorCode, error.dataNumber1);
				} else {
					toast.error("Invite failed. " + error.errorMsg);
				}
			}
		});
	}

	function close() {
		reset();
		props.closeModal();
	}

	const agentInvites = watch("invites");
	let invitesRemaining = agentLimit - activeAgentCount - agentInvites.length;

	if (invitesRemaining < 0) {
		invitesRemaining = 0;
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			modalStyle={modalStyle}
			title="Invite agents"
			showCloseWarning={getValues().invites.some(invite => invite.email != "")}
		>
			<ModalContent>
				<div className="mb-6">
					Invite new agents by email to join your organization.
				</div>
				<AddAgentList control={control} maxInvites={agentLimit - activeAgentCount} />
			</ModalContent>

			<ModalButtons>
				<div className="flex flex-row w-full justify-between">
					<div>
						<span className="ml-2 text-gray-500">
							Invites remaining: <span className="font-semibold">{invitesRemaining}</span>
						</span>
					</div>

					<div className="flex gap-4">
						<Button onClick={close} btnClass="btn-grey" label="Cancel" />
						<Button
							onClick={() => handleSubmit(onSubmit)()}
							btnClass="btn-blue"
							label="Send all invites"
							disabled={getValues().invites.length < 1}
							loading={inviteAgentsReq.isPending}
						/>
					</div>
				</div>
			</ModalButtons>
		</Modal>
	);
}

interface AddAgentListProps {
	control: Control<AgentInviteForm, unknown>;
	maxInvites: number;
}

function AddAgentList(props: AddAgentListProps) {
	const control = props.control;
	// This needs to be a child component to make the field array clear and work properly
	const { fields, append, remove } = useFieldArray({ control, name: "invites" });

	return (
		<>
			<div
				className="grid grid-cols-4 gap-x-4 overflow-y-auto max-h-80 "
				style={{
					gridTemplateColumns: "auto auto 50px 50px"
				}}
			>
				<div className="text-lg">Email</div>
				<div className="text-lg">Team</div>
				<div className="text-lg flex items-center">
					Admin
					<QuestionTooltip tip="Administrators are able to change settings and add/delete things like mailboxes." />
				</div>
				<div className="justify-self-end mr-1"></div>

				{fields.map((field, index) =>
					<>
						<W_TextInput
							type={InputTypes.Email}
							key={field.id + "-email"}
							control={control}
							dataname={`invites[${index}].email`}
							mandatory
							autoFocus
							placeholder="agent@company.com"
						/>
						<W_MultiEntitySelect key={field.id + "-team"} control={control} dataname={`invites[${index}].teamIds`} cacheSlice={CacheSlices.Teams} />

						<div className="flex justify-self-center ml-3">
							<W_Tickbox key={field.id + "-admin"} control={control} dataname={`invites[${index}].isAdmin`} valueName={`invites[${index}].isAdmin`} />
						</div>

						<div className="flex justify-center align-center">
							<button key={field.id + "-remove"} type="button" onClick={() => remove(index)}>
								<IoCloseCircleOutline className="rounded-full hover:bg-gray-200" key={field.id + "-"} size={20} />
							</button>
						</div>
					</>
				)}
			</div>
			<div className="flex pt-5">
				<div className="mt-4">
					{ fields.length >= props.maxInvites ?
						<></>

						:

						<Button
							className=""
							onClick={() => append({ email: "", isAdmin: false, teamIds: [] })}
							label="Add another agent"
							icon={<IoAddCircleOutline size={20} />}
							disabled={props.maxInvites <= fields.length}
						/>

					}
				</div>

				<div className="flex grow ml-4">
					{ fields.length >= props.maxInvites &&
						<div className="">
							<AlertBox alertType="info" className="px-2 py-2 h-[38px]">
								<span className="text-wrap">This is your last remaining agent invite.</span>
							</AlertBox>
						</div>}
				</div>
			</div>
		</>
	);
}
