import { useEffect } from "react";
import { RedeemOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

interface ClfConfig {
	/** CSS selector where to inject the badge */
	selector: string;
	app_id: string;
}

declare global {
	interface Window {
		CLF_config?: ClfConfig;
	}
}

export function ReleaseUpdatesButton() {
	useEffect(() => {
		const config: ClfConfig = {
			selector: "#headway-button", // CSS selector where to inject the badge
			app_id: "76449a54-410d-41aa-b41e-f9ccfe0e66d7",
		};

		window.CLF_config = config;

		const script = document.createElement("script");
		script.async = true;
		script.src = "https://widget.changelogfy.com/index.js";

		document.head.appendChild(script);
	}, []);

	return (
		<div id="headway-button">
			<Tooltip title="Changelog">
				<IconButton >
					<RedeemOutlined />
				</IconButton>
			</Tooltip>
		</div>
	);
}
