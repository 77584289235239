import { MdSupportAgent } from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineBookOpen } from "react-icons/hi";
import { Box } from "@mui/material";
import { ReportingSidebarButton } from "@/Pages/Reporting/ReportingSidebar/ReportingSidebarButton";

interface ReportingSidebarProps {
	activeReport: string;
}

export function ReportingSidebar(props: ReportingSidebarProps) {
	return (
		<Box
			sx={theme => ({ bgcolor: theme.palette.background.paper })}
			className="w-72 shrink-0 border-r border-gray-300 overflow-y-auto"
		>
			<div className="border-b border-gray-300 p-4">
				<div className="text-base font-semibold">Reporting</div>
			</div>

			<div className="border-b border-gray-300 py-4">
				<div className="mx-2">
					<ReportingSidebarButton activeReport={props.activeReport} name="Dashboard" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Response Times" />
				</div>
			</div>

			<div className="border-b border-gray-300 py-4">
				<div className="text-base font-semibold grow flex align-middle mx-5 my-1">
					<div className="text-xl mr-1 flex items-center"><MdSupportAgent /></div>
					<div>Agents</div>
				</div>
				<div className="mx-2">
					<ReportingSidebarButton activeReport={props.activeReport} name="Busiest Team" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Total Ticket Replies" />
				</div>
			</div>

			<div className="border-b border-gray-300 py-4">
				<div className="text-base font-semibold grow flex align-middle mx-5 my-1">
					<div className="text-xl mr-1 flex items-center"><IoTicketOutline /></div>
					<div>Tickets</div>
				</div>
				<div className="mx-2">
					{/* <ReportingSidebarButton activeReport={activeReport} name="First Response Time" />
						<ReportingSidebarButton activeReport={activeReport} name="First Response Time By Agent" />*/}
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets by Category" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets by Priority" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets by Status" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets by Source" />
					{/* <ReportingSidebarButton activeReport={activeReport} name="Time To Resolution" />*/}
				</div>
			</div>

			<div className="border-b border-gray-300 py-4">
				<div className="text-base font-semibold grow flex align-middle mx-5 my-1">
					<div className="text-xl mr-1 flex items-center"><AiOutlineUser /></div>
					<div>Users</div>
				</div>
				<div className="mx-2">
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets per Company" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Tickets per User" />
					<ReportingSidebarButton activeReport={props.activeReport} name="Total Users" />
				</div>
			</div>

			<div className="border-b border-gray-300 py-4">
				<div className="text-base font-semibold grow flex align-middle mx-5 my-1">
					<div className="text-xl mr-1 flex items-center"><HiOutlineBookOpen /></div>
					<div>Knowledge base</div>
				</div>
				<div className="mx-2">
					<ReportingSidebarButton activeReport={props.activeReport} name="Missed Search Results" />
				</div>
			</div>
		</Box>
	);
}
