import { AiOutlineBarChart, AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { IoTicketOutline } from "react-icons/io5";
import { HiOutlineBookOpen } from "react-icons/hi";
import { SidebarButton } from "@/Components/Sidebar/SidebarButton";
import { Keys } from "@shared/Components/Key/Keys";

interface SideBarButtonsProps {
	expanded: boolean;
}

export function SideBarButtons(props: SideBarButtonsProps) {
	return (
		<>
			<SidebarButton
				expanded={props.expanded}
				title="Home"
				link="/home"
				icon={<AiOutlineHome />}
				tooltipShortcut={<Keys label="Home" keys={["G", "then", "H"]} />}
			/>
			<SidebarButton
				expanded={props.expanded}
				title="Tickets"
				link="/tickets"
				icon={<IoTicketOutline />}
				tooltipShortcut={<Keys label="Tickets" keys={["G", "then", "T"]} />}
			/>
			<SidebarButton
				expanded={props.expanded}
				title={"Users & Companies"}
				link="/companies"
				icon={<AiOutlineUser />}
				tooltipShortcut={<Keys label="Users & Companies" keys={["G", "then", "C"]} />}
			/>
			<SidebarButton
				expanded={props.expanded}
				title="Knowledge Base"
				link="/kb"
				icon={<HiOutlineBookOpen />}
				tooltipShortcut={<Keys label="Knowledge Base" keys={["G", "then", "K"]} />}
			/>
			<SidebarButton
				expanded={props.expanded}
				title="Reporting"
				link="/reporting"
				icon={<AiOutlineBarChart />}
				tooltipShortcut={<Keys label="Reporting" keys={["G", "then", "R"]} />}
			/>
		</>
	);
}
