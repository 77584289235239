import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Button, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { BuyGodeskModal } from "@/Pages/Config/Account/BuyGodeskModal";
import { useFulfillCheckoutSession, useGetAccountStatus, useGetStripePortalLink } from "@/Api/genApi";
import { GodeskPurchasedModal } from "@/Pages/Config/Account/GodeskPurchasedModal";
import { useCache } from "@/Hooks/useCache";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";

export default function Account() {
	document.title = "Account | Config | GoDesk";

	const { refreshCache } = useCache();

	const [searchParams] = useSearchParams();
	const actionParam = searchParams.get("action");

	// Resubmit the purchase using session_id
	const checkoutSessionId = searchParams.get("session_id");

	const [buyGodeskModalOpen, setBuyGodeskModalOpen] = useState(false);
	const [godeskPurchasedModalOpen, setGodeskPurchasedModalOpen] = useState(actionParam == "purchase_success");

	const getAccountStatusReq = useGetAccountStatus();
	const getStripePortalLinkReq = useGetStripePortalLink({ query: {
		retry: () => false,
		refetchInterval: Infinity,
		refetchOnWindowFocus: false
	} });

	const fulfillCheckoutSessionReq = useFulfillCheckoutSession();

	// Submit the session to be redeemed if needed.
	// This should be done by Stripe already, but we do it here too to be safe. https://docs.stripe.com/checkout/fulfillment#trigger-fulfillment-on-landing-page
	useEffect(() => {
		if (checkoutSessionId != null) {
			fulfillCheckoutSessionReq.mutate({ data: { checkoutSessionId: checkoutSessionId } }, {
				onSuccess: () => refreshCache()
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps -- Only need to watch the sessionId.
	}, [checkoutSessionId]);

	if (getAccountStatusReq.isPending || getAccountStatusReq.data == null || getStripePortalLinkReq.isPending) {
		return;
	}

	const currentPeriodEnd = getAccountStatusReq.data.currentPeriodEnd != null ? DateTime.fromISO(getAccountStatusReq.data.currentPeriodEnd) : null;

	const isCancelling = currentPeriodEnd != null && currentPeriodEnd > DateTime.now();

	return (
		<FormParent
			title="Account"
			description="Managing licences, billing, and invoices."
		>
			<FormSection title="Your plan">
				<FormReadBox>
					<FormReadPair
						name="Agent licences"
						value={getAccountStatusReq.data.recurringLicences}
					/>

					{getAccountStatusReq.data.lifetimeLicences > 0 ?
						<FormReadPair
							name="Lifetime licences"
							value={getAccountStatusReq.data.lifetimeLicences}
						/> : null}

					{getAccountStatusReq.data.cancelled ?
						<FormReadPair
							name="Status"
							value={isCancelling ? "Cancelling" : "Cancelled"}
						/> : null}

					{currentPeriodEnd != null ?
						<FormReadPair
							name="Current billing period ends"
							value={currentPeriodEnd.toLocaleString(DateTime.DATETIME_SHORT)}
						/> : null}
				</FormReadBox>
			</FormSection>

			{getAccountStatusReq.data.isTrial &&
				<FormSection>
					<Alert severity="info">
						You are currently on a trial instance of GoDesk.
					</Alert>
				</FormSection>}

			<FormSection>
				<Stack direction="row" spacing={1}>
					{getStripePortalLinkReq.data == null &&
						<Button
							onClick={() => setBuyGodeskModalOpen(true)}
							variant="contained"
						>
							Upgrade now
						</Button>}

					<Button
						onClick={() => {
							if (getStripePortalLinkReq.data != null) {
								window.location.assign(getStripePortalLinkReq.data);
							}
						}}
						variant="contained"
						disabled={getStripePortalLinkReq.data == null}
					>
						Modify account
					</Button>
				</Stack>
			</FormSection>

			<BuyGodeskModal isOpen={buyGodeskModalOpen} closeModal={() => setBuyGodeskModalOpen(false)} />

			<GodeskPurchasedModal isOpen={godeskPurchasedModalOpen} closeModal={() => setGodeskPurchasedModalOpen(false)} />
		</FormParent>
	);
}
