import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { OutlinedInput } from "@mui/material";
import api from "@/Api/Api";

export default function HeaderSearch() {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState<string>("");

	async function handleKeyDown(event: React.KeyboardEvent) {
		if (event.key == "Enter") {

			const safeQuery = searchTerm.trim();

			const isTicketId = safeQuery.match(/#(\d+)/);

			// If a number is typed with a leading # then check if a ticket exists with the number imputed.
			if (isTicketId != null && isTicketId[1] != null) {
				const res = await api.BasicHead("/tickets/" + isTicketId[1]);

				if (res.successful) {
					navigate("/tickets/" + isTicketId[1]);
					return;
				}
			}

			navigate("/search/" + safeQuery);
		}
	}

	return (
		<div className="relative w-56">
			<OutlinedInput
				placeholder="Search"
				endAdornment={<AiOutlineSearch />}
				onChange={e => setSearchTerm(e.target.value)}
				onKeyDown={handleKeyDown}
				sx={{ height: "30px" }}
			/>
		</div>
	);
}
