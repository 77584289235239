import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import { useGetTicketsBySourceReport } from "@/Api/genApi";
import { SourceDay } from "@/Api/genApi.schemas";

interface TicketsByStatusProps {
	dateRange: [DateTime, DateTime];
}

export function TicketsBySource(props: TicketsByStatusProps) {
	const reportReq = useGetTicketsBySourceReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	if (reportReq.data == undefined) {
		return "Report loading...";
	}

	return (
		<>
			<div className="text-3xl my-5">
				Tickets by Status
			</div>

			<div className="my-3">
				Shows tickets in the reporting period split by status.
			</div>

			<div className="my-5">
				<Chart axisTitles={{ xLabel: "date", yLabel: "source" }} data={reportReq.data} />
			</div>
		</>
	);
}

interface ChartProps {
	axisTitles: { xLabel: string; yLabel: string };
	data: SourceDay[];
}

function Chart(props: ChartProps) {
	const labels: string[] = props.data.map(entry => entry.day);

	const dataEmail: number[] = props.data.map(entry => entry.emailCount);
	const dataPortal: number[] = props.data.map(entry => entry.portalCount);
	const dataAgent: number[] = props.data.map(entry => entry.agentCount);
	const dataUnknown: number[] = props.data.map(entry => entry.unknownCount);

	const data: ChartData<"bar", number[], string> = {
		labels: labels,

		datasets: [
			{
				label: "Email",
				data: dataEmail,
			},
			{
				label: "Portal",
				data: dataPortal,
			},
			{
				label: "Agent",
				data: dataAgent
			},
			{
				label: "Unknown",
				data: dataUnknown,
			}
		]
	};

	const options: ChartOptions<"bar"> = {
		indexAxis: "x",
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			},
			x: {
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: true
			},
			colors: {
				enabled: true
			}
		}
	};

	return (
		<div className="bg-white p-10 rounded border border-gray-300 max-w-3xl">
			<Bar data={data} options={options} />
		</div>
	);
}
