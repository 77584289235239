import { ReactNode } from "react";
import Box from "@mui/material/Box";
import Key from "@shared/Components/Key/Key";

interface KeysProps {
	label: string;
	keys: string[];
}

export function Keys(props: KeysProps) {

	const keys: ReactNode[] = props.keys?.map((key, index) => {
		if (key === "then") {
			return (
				<span key={index} className="inline-block">
					then
				</span>
			);
		} else {
			return <span key={index}><Key>{key}</Key></span>;
		}
	});

	return (
		<Box className="inline-flex gap-1 items-center">
			<span className="mr-1">
				{props.label}
			</span>

			{keys}
		</Box>
	);
}
