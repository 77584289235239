import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";
import { DateRangePicker } from "@/Components/DateRangePicker/DateRangePicker";
import { Dashboard } from "@/Pages/Reporting/Dashboard/Dashboard";
import { TotalTicketReplies } from "@/Pages/Reporting/Reports/TotalTicketReplies";
import { TicketsPerCompany } from "@/Pages/Reporting/Reports/TicketsPerCompany";
import { TicketsPerUser } from "@/Pages/Reporting/Reports/TicketsPerUser";
import { TotalUsers } from "@/Pages/Reporting/Reports/TotalUsers";
import { TicketsByPriority } from "@/Pages/Reporting/Reports/TicketsByPriority";
import { TicketsByStatus } from "@/Pages/Reporting/Reports/TicketsByStatus";
import { BusiestTeam } from "@/Pages/Reporting/Reports/BusiestTeam";
import { Button } from "@/Components/Button/Button";
import { ResponseTimes } from "@/Pages/Reporting/Reports/ResponseTimes";
import { TicketsByCategory } from "@/Pages/Reporting/Reports/TicketsByCategory";
import { useCache } from "@/Hooks/useCache";
import { MissedSearchResult } from "@/Pages/Reporting/Reports/MissedSearchResults";
import { ReportingSidebar } from "@/Pages/Reporting/ReportingSidebar/ReportingSidebar";
import { TicketsBySource } from "@/Pages/Reporting/Reports/TicketsBySource";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { SettingString } from "@shared/Enums/SettingEnums";

export function Reporting() {
	document.title = "Reporting | GoDesk";

	const [searchParams, setSearchParams] = useSearchParams();
	const { cache } = useCache();
	const timezone = cache.getSettingString(SettingString.WORKDAY_TIMEZONE);
	const navigate = useNavigate();

	const [reportingPeriod, setReportingPeriod] = useState<[DateTime, DateTime]>(getDefaultReportingPeriod());

	const paramsReport = searchParams.get("report");

	const activeReport = paramsReport != null ? paramsReport.toLowerCase().replaceAll(" ", "-") : "dashboard";

	function getDefaultReportingPeriod(): [DateTime, DateTime] {
		// Try and get period from URL params.
		const paramsFrom = searchParams.get("dateFrom");
		const paramsTo = searchParams.get("dateTo");

		if (paramsFrom != null && paramsTo != null) {
			const from = DateTime.fromSQL(paramsFrom);
			const to = DateTime.fromSQL(paramsTo);

			if (from.isValid && to.isValid) {
				return [from, to];
			}
		}

		// Do last 7 days.
		const now = DateTime.now();

		const startOf7daysAgo = now.minus({ day: 7 }).startOf("day");
		const endOfYesterday = now.minus({ day: 1 }).endOf("day");

		return [startOf7daysAgo, endOfYesterday];
	}

	function setDateRange(range: [DateTime, DateTime]) {
		// Update URL params.
		searchParams.set("dateFrom", range[0].toSQLDate());
		searchParams.set("dateTo", range[1].toSQLDate());

		setSearchParams(searchParams);

		setReportingPeriod(range);
	}

	let page = null;
	switch (activeReport) {
		case "dashboard":
			page = <Dashboard dateRange={reportingPeriod} />;
			break;
		case "response-times":
			page = <ResponseTimes dateRange={reportingPeriod} />;
			break;

		case "busiest-team":
			page = <BusiestTeam dateRange={reportingPeriod} />;
			break;
		case "total-ticket-replies":
			page = <TotalTicketReplies dateRange={reportingPeriod} />;
			break;

		case "tickets-by-category":
			page = <TicketsByCategory dateRange={reportingPeriod} />;
			break;
		case "tickets-by-priority":
			page = <TicketsByPriority dateRange={reportingPeriod} />;
			break;
		case "tickets-by-status":
			page = <TicketsByStatus dateRange={reportingPeriod} />;
			break;
		case "tickets-by-source":
			page = <TicketsBySource dateRange={reportingPeriod} />;
			break;

		case "tickets-per-company":
			page = <TicketsPerCompany dateRange={reportingPeriod} />;
			break;
		case "tickets-per-user":
			page = <TicketsPerUser dateRange={reportingPeriod} />;
			break;
		case "total-users":
			page = <TotalUsers dateRange={reportingPeriod} />;
			break;
		case "missed-search-results":
			page = <MissedSearchResult dateRange={reportingPeriod} />;
			break;

		default:
			page = <Dashboard dateRange={reportingPeriod} />;
	}

	return (
		<div className="flex" style={{ height: "100%" }}>
			<ReportingSidebar activeReport={activeReport} />

			<div className="grow p-5 overflow-y-auto">
				<div className="flex flex-row space-x-2">
					<DateRangePicker title="Reporting period" dateRange={reportingPeriod} setDateRange={setDateRange} />
				</div>

				{ timezone == null &&
					<div className="max-w-lg">
						<AlertBox alertType="warning">
							<div>
								You do not currently have a timezone set. Please set your timezone below to ensure reporting is accurate.
								<div className="space-x-2 py-2 flex items-center">
									<Button
										label="Update timezone"
										btnClass="btn-grey"
										onClick={() => navigate("/config/workday")}
									/>
								</div>
							</div>
						</AlertBox>
					</div>}

				<div className="max-w-6xl">
					{page}
				</div>
			</div>
		</div>
	);
}
