import { TicketAgentSection } from "./TicketAgentSection";
import { TicketDetailsSection } from "./TicketDetailsSection";
import { TicketUserSection } from "./TicketUserSection";
import { TicketEmailCCSection } from "./TicketEmailCCSection";
import { Ticket, TicketSource } from "@shared/Entities/EntityTypes";

interface TicketDetailsProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: any): void;
}

export function TicketDetails(props: TicketDetailsProps) {
	return (
		<div>
			<TicketUserSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketEmailCCSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketAgentSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<TicketDetailsSection ticket={props.ticket} handleSubmit={props.handleSubmit} />

			<div className="border-t border-gray-300 p-3">
				Source: {getSourceName(props.ticket.source)}
			</div>
		</div>
	);
}

function getSourceName(source?: TicketSource) {
	switch (source) {
		case TicketSource.AGENT: return "Agent";
		case TicketSource.EMAIL: return "Email";
		case TicketSource.PORTAL: return "Portal";
		case TicketSource.UNKNOWN: return "Unknown";
	}
}
